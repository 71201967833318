import { default as React, useState } from "react";
import CssStyle from "../lib/constants";
import ListBuilder from "./ListBuilder";
import TextField from "./textfield";
export const ScrollSection = ({
  title,
  list,
  displayTextFun,
  isCheckbox,
  selectedItem,
  handleCheckboxChange,
  scrollable = true,
}) => {
  const [searchKeyword, onSearchChange] = useState("");
  const filteredList = list.filter((e) => {
    return displayTextFun(e)
      .toLowerCase()
      .includes(searchKeyword.toLowerCase());
  });

  return (
    <div
      className={`flex-1 border-0 rounded shado ${
        false ? CssStyle.scrollable : CssStyle.non_scrollable
      }`}
    >
      {" "}
      <div className="flex ">
        <TextField
          value={searchKeyword}
          placeholder={`Search ${title}`}
          onChange={(e) => {
            onSearchChange(e.target.value);
          }}
          className="flex-1"
        />
        <button className="ml-4 button" onClick={() => onSearchChange("")}>
          Clear
        </button>
      </div>
      <ListBuilder
        scrollable={true}
        bottomPadding={""}
        dataList={filteredList}
        noItemText={`No ${title}`}
        itemCard={(item, index) => (
          <li
            key={index}
            className={`bg-orange-100 text-left my-2 p-5 rounded-xl`}
          >
            {isCheckbox && (
              <input
                type="checkbox"
                checked={selectedItem[item.id]}
                className="mr-4 transform scale-150 accent-orange-600"
                onChange={() => handleCheckboxChange(item.id)}
              />
            )}
            {displayTextFun(item)}
          </li>
        )}
      />
    </div>
  );
};

export default ScrollSection;
