import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import Moment from "react-moment";
import "../../components/calendar/calendar.css";
import CurriculumService from "../../services/CurriculumService";
import { State, StateBuilder } from "../StateBuilder";
const CalendarBox = ({ date }) => {
  const [state, setState] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dueDates, setDueDates] = useState();
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  useEffect(() => {
    getCurriculumDueDates();
  }, []);

  return (
    <StateBuilder
      state={state}
      successUi={
        <div className="bg-gray-200 rounded-xl">
          <div className="flex p-4">
            <div className="flex-1 text-left text-xl">
              {<Moment format="MMMM  YYYY">{activeStartDate}</Moment>}
            </div>
            <button
              onClick={() => {
                setActiveStartDate(
                  new Date(
                    activeStartDate.setMonth(activeStartDate.getMonth() - 1)
                  )
                );
              }}
            >
              <FaChevronLeft className="ml-2" />
            </button>

            <button
              onClick={() => {
                setActiveStartDate(
                  new Date(
                    activeStartDate.setMonth(activeStartDate.getMonth() + 1)
                  )
                );
              }}
            >
              <FaChevronRight className="ml-2" />
            </button>
          </div>
          <a href="/schedule">
            <Calendar
              activeStartDate={activeStartDate}
              showNavigation={false}
              value={date}
              className="border-0 p-4"
              onClickDay={(d) => {
                setSelectedDate(d);
              }}
              tileClassName="border-2 border-rose-500 "
              tileContent={({ activeStartDate, date, view }) => {
                const dateExists = dueDates.some((item) => {
                  return (
                    item.due_date.getDate() === date.getDate() &&
                    item.due_date.getMonth() === date.getMonth()
                  );
                });

                return view === "month" && dateExists ? (
                  <div className="flex justify-center ">
                    <div className="bg-rc_orange w-1 h-1 rounded-full"> </div>
                  </div>
                ) : (
                  <div className=" w-1 h-1"></div>
                );
              }}
            />
          </a>
          {/*  {dueDates &&
            dueDates
              .filter(
                (item) => item.due_date.getDate() === selectedDate.getDate()
              )
              .map((each) => (
                <div className="text-left p-4 my-1 bg-gray-200 rounded-xl">
                  {each.curriculum.name}
                </div>
              ))} */}
        </div>
      }
    />
  );

  async function getCurriculumDueDates() {
    setState(State.loading);
    try {
      const jsonData = await CurriculumService.getCurriculumDueDates();
      const dueDates = jsonData.map((item) => ({
        ...item,
        due_date: new Date(item.due_date),
      }));
      setDueDates(dueDates);
      setState(State.success);
    } catch (error) {
      setState(State.failed);
    }
  }
};

export default CalendarBox;
