import { default as React } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import logo from "../../imgs/logo.png";
import CssStyle from "../../lib/constants";
import authServices from "../../services/authServices";
import "./sidebar.css";
const Navigation = () => {
  const userRole = localStorage.getItem("userRole");
  const empNav = [
    { title: "Home", route: "/home" },
    { title: "Trainings", route: "/trainings" },
    { title: "Schedule", route: "/schedule" },
    { title: "Analytics", route: "/analytics" },
    { title: "Certifications", route: "/certifications" },
  ];
  const adminNav = [
    ...(["EMP", "MNG"].includes(userRole) ? empNav : []),
    { title: "Management", route: "/management" },
  ];
  const navItems = userRole == "EMP" ? empNav : adminNav;
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div
      className={`flex flex-col h-screen bg-black w-72 ${CssStyle.scrollable}`}
    >
      <div className="logo p-4 flex">
        <img src={logo} height={100} width={100} />
      </div>
      <nav className="flex flex-col ml-4 flex-1 ">
        <ul className="flex-1">
          {navItems.map((item, index) => {
            const isNavActive = location.pathname === item.route;
            return (
              <div className="bg-white flex flex-col justify-end -my-1">
                <div
                  className={`w-full h-4 bg-black ml-auto  ${
                    isNavActive ? "rounded-br-full" : ""
                  }`}
                ></div>
                <div className="bg-black">
                  <div
                    key={index}
                    className={`nav-item flex-1 font-bold ${
                      isNavActive ? "active" : ""
                    }`}
                  >
                    <a href={item.route}>{item.title}</a>
                  </div>
                </div>
                <div
                  className={`w-full h-4 bg-black ml-auto ${
                    isNavActive ? "rounded-tr-full" : ""
                  }`}
                ></div>
              </div>
            );
          })}
        </ul>
      </nav>
      <button
        onClick={() => {
          authServices.logout().then(() => {
            navigate("/");
          });
        }}
        className=" text-white p-2 m-6 text-left font-bold"
      >
        Logout
      </button>
    </div>
  );
};

export function NavigationWrapper({ Child }) {
  return (
    <div className="">
      <div className="flex lg:hidden justify-center items-center h-screen">
        The content is designed for Desktop
      </div>
      <div className="hidden lg:flex">
        {/* Navigation Side Pane */}
        <div className="">
          <Navigation />
        </div>
        <div
          className={`flex-1 px-8 py-4 flex flex-col h-screen ${CssStyle.non_scrollable}`}
        >
          {Child}
        </div>
      </div>
    </div>
  );
}
export default Navigation;
