import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";

const PercentIndicator = ({ value }) => {
  var displayText = value;
  if (displayText === undefined) displayText = "0";
  else if (parseInt(value) == value) displayText = value;
  else displayText = value.toFixed(0);
  return (
    <div style={{ width: 75, height: 75 }}>
      <CircularProgressbar
        className="text-base "
        value={value === undefined ? 0 : value / 100}
        maxValue={1}
        styles={{
          path: { stroke: "#d8781f" },
          text: {
            // Text color
            fill: "#000000",
            // Text size
            fontSize: "28px",
          },
        }}
        text={`${displayText}%`}
      />
    </div>
  );
};

export default PercentIndicator;
