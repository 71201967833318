import React from "react";
import CssStyle from "../lib/constants";

const ListBuilder = ({
  dataList,
  noItemText,
  itemCard,
  scrollable = true,
  bottomPadding = 0,
}) => {
  if (dataList.length == 0) {
    return <div className="my-4">{noItemText}</div>;
  }
  return (
    <div
      className={`${
        scrollable ? CssStyle.scrollable : CssStyle.non_scrollable
      }`}
    >
      <ul className="">
        {dataList.map((item, index) => {
          if (index + 1 == dataList.length)
            return (
              <div>
                {itemCard(item, index)}
                <div className={`${bottomPadding}`}></div>
              </div>
            );
          return itemCard(item, index);
        })}
      </ul>
    </div>
  );
};

export default ListBuilder;
