import React, { useState } from "react";
import CssStyle from "../../lib/constants";
import "./tabbar.css";
const TabBar = ({ tabs, scrollable = false }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]?.id);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="flex-1 h-full">
      <div className="flex mb-0 pb-2 h-14 bg-white">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`tab-button font-bold ${
              tab.id === activeTab ? "active" : ""
            }`}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div
        className={`h-full ${
          scrollable ? CssStyle.scrollable : CssStyle.non_scrollable
        } mt-8`}
      >
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab-panel ${tab.id === activeTab ? "active" : ""}`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabBar;
