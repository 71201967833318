import React from "react";
import CssStyle from "../lib/constants";
import { StateBuilder } from "./StateBuilder";
import HeadingRow from "./heading-row/HeadingRow";
import { NavigationWrapper } from "./sidebar/Navigation";

const PageWrapper = ({
  PageHeading,
  state,
  successUi,
  sidebar,
  scrollable = false,
  side_scrollable = false,
}) => {
  return (
    <NavigationWrapper
      Child={
        <StateBuilder
          state={state}
          successUi={
            <div class="flex flex-col h-full">
              <HeadingRow heading={PageHeading} />

              <div class="flex-grow overflow-y-hidden flex">
                <div
                  className={` flex-1 h-full ${
                    scrollable ? CssStyle.scrollable : CssStyle.non_scrollable
                  }`}
                >
                  {successUi}
                </div>
                <div
                  className={` w-80 ml-8 ${
                    side_scrollable
                      ? CssStyle.scrollable
                      : CssStyle.non_scrollable
                  }`}
                >
                  {sidebar}
                </div>
              </div>
            </div>
          }
        />
      }
    />
  );
};

export default PageWrapper;
