import React, { useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";
import ListBuilder from "../components/ListBuilder";
import PageWrapper from "../components/PageWrapper";
import { State, StateBuilder } from "../components/StateBuilder";
import TrainingComplianceCard from "../components/TrainingCompliaceCard";
import TabBar from "../components/tabbar/Tabbar";
import TrainingService from "../services/TrainingService";
const Trainings = () => {
  const [state, setState] = useState(State.initial);
  const [complianceBriefState, setComplianceBriefState] = useState(
    State.initial
  );
  const [trainings, setTrainings] = useState({});
  const [
    {
      avg_attempts,
      avg_score,
      user,
      total_trainings,
      completed_trainings,
      on_time_completion,
      training_completed_month,
      training_due_week,
      training_progress_brief,
    },
    setComplianceBrief,
  ] = useState({});
  useEffect(() => {
    getTraining();
  }, []);
  useEffect(() => {
    getTrainingComplianceBrief();
  }, []);

  const trainingCardBuilder = (training, index) => (
    <TrainingComplianceCard training={training} key={index} />
  );
  const trainingTabs = [
    {
      id: "due",
      label: "Due",
      content: (
        <StateBuilder
          state={state}
          successUi={
            <ListBuilder
              dataList={trainings.pendingTraining}
              noItemText={"No Training"}
              itemCard={trainingCardBuilder}
              bottomPadding={"h-40"}
            />
          }
        />
      ),
    },
    {
      id: "completed",
      label: "Completed",
      content: (
        <StateBuilder
          state={state}
          successUi={
            <ListBuilder
              dataList={trainings.completedTraining}
              noItemText={"No Training"}
              itemCard={trainingCardBuilder}
              bottomPadding={"h-40"}
            />
          }
        />
      ),
    },
    {
      id: "all",
      label: "All",
      content: (
        <StateBuilder
          state={state}
          successUi={
            <ListBuilder
              dataList={trainings.allTraining}
              noItemText={"No Training"}
              itemCard={trainingCardBuilder}
              bottomPadding={"h-40"}
            />
          }
        />
      ),
    },
  ];
  return (
    <PageWrapper
      PageHeading="Trainings"
      state={complianceBriefState}
      side_scrollable={true}
      successUi={
        <div className="flex-1 h-full">
          <TabBar tabs={trainingTabs} scrollable={true} />
        </div>
      }
      sidebar={
        <div className="">
          <div className="h-14"></div>
          <StateBuilder
            state={complianceBriefState}
            successUi={
              <div>
                <div className="flex flex-col">
                  <div className="flex-cols w-full text-left">
                    {training_progress_brief?.training__curriculum__name &&
                      training_progress_brief?.total_count > 0 && (
                        <div className="bg-gray-200 rounded-xl p-4 my-8">
                          <h2 className="text-5xl font-semibold">
                            {training_progress_brief?.completed_count && (
                              <>{training_progress_brief.completed_count}</>
                            )}{" "}
                            of{" "}
                            {training_progress_brief?.total_count && (
                              <>{training_progress_brief.total_count}</>
                            )}
                          </h2>
                          <br></br>
                          <p>
                            Training completed in{" "}
                            {training_progress_brief?.training__curriculum__name && (
                              <>
                                {
                                  training_progress_brief.training__curriculum__name
                                }
                              </>
                            )}
                          </p>
                        </div>
                      )}
                    <div className="bg-gray-200 rounded-xl p-4  my-8">
                      <h2 className="text-5xl font-semibold">
                        {training_due_week}
                      </h2>
                      <br></br>
                      <p>Training due this week</p>
                    </div>

                    <div className="bg-gray-200 rounded-xl p-4">
                      <h2 className="text-5xl font-semibold">
                        {training_completed_month}
                      </h2>
                      <br></br>
                      <p>Training completed this month</p>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      }
    />
  );

  async function getTraining() {
    setState(State.loading);
    try {
      const pendingTraining = await TrainingService.getTrainingsCompliances({
        trainingStatus: "pending",
      });
      const completedTraining = await TrainingService.getTrainingsCompliances({
        trainingStatus: "completed",
      });
      const allTraining = await TrainingService.getTrainingsCompliances();
      setTrainings({
        pendingTraining: [...pendingTraining],
        completedTraining: completedTraining,
        allTraining: allTraining,
      });
      setState(State.success);
    } catch (error) {
      setState(State.failed);
    }
  }

  async function getTrainingComplianceBrief() {
    setComplianceBriefState(State.loading);
    try {
      const complianceBrief =
        await TrainingService.getTrainingComplianceBrief();
      setComplianceBrief(complianceBrief);
      setComplianceBriefState(State.success);
    } catch (error) {
      setComplianceBriefState(State.failed);
    }
  }
};
export default Trainings;
