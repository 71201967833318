import LinearProgress from "@material-ui/core/LinearProgress";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { State } from "../components/StateBuilder";
import { Dialogs } from "../lib/exceptions";
import QuizService from "../services/QuizService";
import "../styles.css";
function TakeQuizPage() {
  const { id } = useParams();
  const [state, setState] = useState(State.initial);
  const [quiz, setQuiz] = useState([]);
  const [answers, setAnswers] = useState([]);
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState();
  useEffect(() => {
    getQuiz();
  }, []);

  const handleAnswerChange = (questionId, selectedOption) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedOption,
    }));
  };
  const q = quiz[currentQuestion];

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));
  if (q) {
    return (
      <div className="m-auto p-10 w-2/4 flex flex-col h-screen">
        <div className="text-left font-bold">
          Question {currentQuestion + 1} of {quiz.length}
        </div>
        <QuestionCard
          q={q}
          handleAnswerChange={handleAnswerChange}
          selectedOption={answers[q.id]}
        />
        <div className="flex items-center">
          <div className="flex-1 mr-8">
            <BorderLinearProgress
              className="rounded "
              sx={{
                backgroundColor: "bg-red-500",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "pink",
                },
              }}
              variant="determinate"
              value={(currentQuestion * 100) / quiz.length}
            />
          </div>
          {currentQuestion < quiz.length - 1 ? (
            <button
              className="button"
              onClick={() => setCurrentQuestion(currentQuestion + 1)}
            >
              Next
            </button>
          ) : (
            <button className="button" onClick={onSubmit}>
              Submit
            </button>
          )}
        </div>
      </div>
    );
  }
  return <div>loading</div>;

  async function getQuiz() {
    setState(State.loading);
    try {
      const res = await QuizService.getQuiz(id);

      const quizWithOptions = res.map((q) => ({
        ...q,
        options: [
          { value: "a", label: q.option_a },
          { value: "b", label: q.option_b },
          { value: "c", label: q.option_c },
          { value: "d", label: q.option_d },
          { value: "e", label: q.option_e },
        ],
      }));
      setQuiz(quizWithOptions);
      setCurrentQuestion(0);
    } catch (error) {}
  }

  async function onSubmit() {
    try {
      const ans = Object.keys(answers).map((key) => ({
        id: key,
        answer: answers[key],
      }));
      const res = await QuizService.submitAnswer(id, ans);
      navigate("/quiz-result", { state: res });
    } catch (error) {
      Dialogs.showAlert(error);
    }
  }
}

function QuestionCard({ q, handleAnswerChange, selectedOption, key }) {
  return (
    <div className="flex-col">
      <p className="text-left">{q.question_text}</p>
      <div className="max-w-xl_ mx-auto my-5">
        {q.options.map((e) => (
          <div
            onClick={() => handleAnswerChange(q.id, e.value)}
            key={e.id}
            className={`option flex items-center my-2 p-5 rounded-md  ${
              selectedOption == e.value ? "bg-green-700" : "bg-gray-100"
            }`}
          >
            <div>
              <input
                checked={e.value === selectedOption}
                type="radio"
                value={e.value}
                name={q.id}
                id={`${q.id}-${e.value}`}
                onChange={() => handleAnswerChange(q.id, e.value)}
                className="mr-2 transform scale-150 accent-green-600"
              />
            </div>
            <label
              htmlFor={`${q.id}`}
              className={`text-lg flex-1 cursor-pointer  text-center ${
                selectedOption == e.value ? "text-white" : "text-black"
              }`}
            >
              {e.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TakeQuizPage;
