import React, { useEffect, useState } from "react";
import PageWrapper from "../components/PageWrapper";
import ScrollSection from "../components/ScrollSection";
import { State, StateBuilder } from "../components/StateBuilder";
import CurriculumService from "../services/CurriculumService";
import UserService from "../services/UserService";

const AssignCurriculum = () => {
  const [state, setState] = useState(State.initial);
  const [curriculum, setCurriculum] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [dueDate, setDueDate] = useState(undefined);
  const [selectedCurriculum, setSelectedCurriculum] = useState({});
  const [selectedEmployees, setSelectedEmployees] = useState({});

  useEffect(() => {
    getData();
  }, []);

  return (
    <PageWrapper
      PageHeading={"Assign Curriculum"}
      state={state}
      scrollable={false}
      successUi={
        <div className="flex flex-1 h-full m-8">
          <StateBuilder state={state} successUi={<SuccessUi />} />
        </div>
      }
      sidebar={
        <div className="basis-1/5 p-4">
          <input
            type="date"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            className="my-4 p-2 w-full border rounded-md"
          />
          <button onClick={onAssignCurriculum} className="button">
            Submit
          </button>
        </div>
      }
    />
  );

  async function getData() {
    try {
      const res = await getEmployees();
      const curriculum = await getCurriculum();
      setEmployee(res);
      setCurriculum(curriculum);
      setState(State.success);
    } catch (e) {}
  }

  async function getEmployees() {
    try {
      const res = await UserService.getEmployees();
      return res;
    } catch (error) {}
  }

  async function getCurriculum() {
    try {
      const res = await CurriculumService.getCurriculum();
      return res;
    } catch (error) {}
  }

  function handleCurriculumCheckboxChange(curriculumId) {
    setSelectedCurriculum((prevState) => ({
      ...prevState,
      [curriculumId]: !prevState[curriculumId],
    }));
  }

  function handleEmployeeCheckboxChange(empId) {
    setSelectedEmployees((prevState) => ({
      ...prevState,
      [empId]: !prevState[empId],
    }));
  }

  async function onAssignCurriculum() {
    const selectedCurriculumIds = Object.keys(selectedCurriculum).filter(
      (id) => selectedCurriculum[id]
    );
    const selectedEmployeeIds = Object.keys(selectedEmployees).filter(
      (id) => selectedEmployees[id]
    );

    // Perform further actions with selectedCurriculumIds, such as submitting to backend

    try {
      const res = await CurriculumService.assignCurriculums(
        selectedEmployeeIds,
        selectedCurriculumIds,
        dueDate
      );

      alert("Curriculum Assigned");
      return res;
    } catch (error) {}
  }

  function SuccessUi() {
    if (employee.length === 0) {
      return <div>No Employee</div>;
    }
    return (
      <div className="flex flex-1">
        {/* 3 cols */}
        <ScrollSection
          title={"Curriculum"}
          list={curriculum}
          isCheckbox={true}
          selectedItem={selectedCurriculum}
          handleCheckboxChange={handleCurriculumCheckboxChange}
          displayTextFun={(e) => {
            return e.name;
          }}
        />
        <div className="w-16" />
        <ScrollSection
          title={"Employee"}
          list={employee}
          isCheckbox={true}
          selectedItem={selectedEmployees}
          handleCheckboxChange={handleEmployeeCheckboxChange}
          displayTextFun={(e) => {
            return `${e.id} ${e.username}`;
          }}
        />
      </div>
    );
  }
};

export default AssignCurriculum;
