import React from "react";
import "../styles.css";

import { useNavigate } from "react-router-dom";

function TrainingComplianceCard({ training, index }) {
  const navigate = useNavigate();
  return (
    <li key={index} style={{ textAlign: "left" }} className="">
      <div className="bg-gray-200 rounded-xl p-8 mb-8 flex">
        <div className="flex-1">
          <h2 className="text-xl font-semibold text-rc_orange">
            {training.code}{" "}
          </h2>

          <h2 className="text-xl font-semibold">{training.curriculum} </h2>
          <p className="text-xl">{training.title}</p>
          <div className="mt-4 debug">
            <strong>Attempts:</strong> {training.attempts}
            <br />
            <strong>Status:</strong> {training.status}
            <br />
            {training.status === "completed" && (
              <div>
                <strong>Score:</strong> {training.score}
                <br />
                <strong>Completion Date:</strong>{" "}
                {training.completion_date || "N/A"}
              </div>
            )}
          </div>
        </div>
        {training.status === "pending" && (
          <div className="flex flex-col items-end">
            <a
              href={training.training_material}
              target="_blank"
              className="mb-4"
            >
              <button className="button-rounded-md">Launch</button>
            </a>
            {training.quiz_exist && (
              <button
                className="button-rounded-md"
                onClick={() => {
                  navigate(`/quiz/${training.id}`);
                }}
              >
                Test Knowledge
              </button>
            )}
          </div>
        )}
      </div>
    </li>
  );
}

export default TrainingComplianceCard;
