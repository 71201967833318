import { get, post } from "../lib/api";

class QuizService {
  async getQuiz(trainingId) {
    try {
      const response = await get(`compliance/start-quiz/${trainingId}/`);
      return response;
    } catch (error) {}
  }

  async submitAnswer(trainingId, answerList) {
    const response = await post(
      `compliance/start-quiz/${trainingId}/`,
      answerList
    );
    return response;
  }
  catch(error) {}
}

export default new QuizService();
