import { get, post } from "../lib/api";

class TrainingService {
  async getTrainingsCompliances({ trainingStatus = null } = {}) {
    const params = {};
    if (trainingStatus !== null) {
      params.status = trainingStatus;
    }
    try {
      const response = await get("compliance/training-compliance/", params);
      return response;
    } catch (error) {}
  }
  async getTrainings({ trainingStatus = null } = {}) {
    const params = {};
    if (trainingStatus !== null) {
      params.status = trainingStatus;
    }
    try {
      const response = await get("compliance/training/", params);
      return response;
    } catch (error) {}
  }

  async addTrainings(training) {
    try {
      if (training.training_material_file instanceof File) {
      }
      var form_data = new FormData();
      for (var key in training) {
        form_data.append(key, training[key]);
      }

      const response = await post("compliance/training/", form_data);
      return response;
    } catch (error) {}
  }

  async getTrainingComplianceBrief() {
    try {
      const userId = localStorage.getItem("userID");
      const response = await get(
        `compliance/training-compliance-brief/${userId}/`
      );
      return response;
    } catch (error) {}
  }

  async getTrainingComplianceBriefs() {
    try {
      const response = await get("compliance/training-compliance-brief/");
      return response;
    } catch (error) {}
  }
}
export default new TrainingService();
