import { default as React, useEffect, useState } from "react";
import PageWrapper from "../components/PageWrapper";
import ScrollSection from "../components/ScrollSection";
import { State, StateBuilder } from "../components/StateBuilder";
import TextField from "../components/textfield";
import CssStyle from "../lib/constants";
import CurriculumService from "../services/CurriculumService";
import TrainingService from "../services/TrainingService";
const AddTraining = () => {
  const [trainingData, setTrainingData] = useState({
    title: undefined,
    code: undefined,
    curriculum: undefined,
    training_material_link: undefined,
    description: undefined,
    training_material_file: undefined,
  });
  const [trainings, setTrainings] = useState([]);
  const [state, setState] = useState(State.initial);
  const [curriculums, setCurriculums] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const formData = new FormData();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const trainings = await TrainingService.getTrainings();
      const curriculums = await CurriculumService.getCurriculum();
      setTrainings(trainings);
      setCurriculums(curriculums);
      setState(State.success);
    } catch (e) {}
  }
  const handleFileChange = (event) => {
    setTrainingData((prevData) => ({
      ...prevData,
      training_material_file: event.target.files[0],
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTrainingData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState(State.loading);
    try {
      await TrainingService.addTrainings(trainingData);
      // Clear form fields after successful submission
      setTrainingData({
        title: undefined,
        code: undefined,
        curriculum: undefined,
        training_material_link: undefined,
        training_material_file: undefined,
        description: undefined,
      });
      alert("Training added successfully!");
      getData();
    } catch (error) {
      console.error("Error adding Training:", error);
      alert("Failed to add Training. Please try again.");
    }
  };

  const Form = () => {
    return (
      <div className={`flex flex-1 justify  p-4 ${CssStyle.scrollable} `}>
        <form
          onSubmit={handleSubmit}
          className="bg-white p-6 rounded-lg w-full max-w-lg"
        >
          <TextField
            type="text"
            id="title"
            name="title"
            label="Title"
            value={trainingData.title}
            onChange={handleChange}
            required
            className="mb-8"
          />
          <TextField
            type="text"
            id="code"
            name="code"
            label="Code"
            value={trainingData.code}
            onChange={handleChange}
            required
            className="mb-8"
          />
          <TextField
            id="description"
            name="description"
            label="Description"
            value={trainingData.description}
            onChange={handleChange}
            required
            className="mb-8"
          />
          <TextField
            id="training_material_link"
            name="training_material_link"
            label="Material Link"
            value={trainingData.training_material_link}
            onChange={handleChange}
            required
            className="mb-8"
          />
          <div>
            {" "}
            <label htmlFor="File" className="block text-gray-700 font-medium">
              File
            </label>
            <input
              type="file"
              className="p-2 w-full border rounded-xl"
              onChange={handleFileChange}
            ></input>
          </div>

          <DropDown
            className="mb-20"
            list={curriculums}
            label="Curriculum"
            name="curriculum"
            value={trainingData.curriculum}
            optionValue={(e) => e.id}
            handleChange={handleChange}
            displayTextFun={(e) => {
              return e.name;
            }}
          />
          <button
            type="submit"
            className="w-full text-white py-2 rounded-md bg-gray-900 transition duration-300"
          >
            Submit
          </button>
        </form>
      </div>
    );
  };

  return (
    <PageWrapper
      PageHeading={"Add Training"}
      state={state}
      scrollable={true}
      successUi={
        <div className="flex flex-1 flex-grow m-8">
          {Form()}
          <StateBuilder
            state={state}
            initialUi={<div>random</div>}
            LoadingUi={
              <>
                <h2>Please Wait, Questions are being prepared!!</h2>
              </>
            }
            successUi={
              <ScrollSection
                title={"Trainings"}
                list={trainings}
                displayTextFun={(e) => {
                  return e.title;
                }}
              />
            }
          />
        </div>
      }
    />
  );
};

const DropDown = ({
  list,
  displayTextFun,
  className,
  label,
  value,
  name,
  optionValue,
  handleChange,
}) => {
  return (
    <div className={className}>
      {label && (
        <label htmlFor={label} className="block text-gray-700 font-medium mb-2">
          {label}
        </label>
      )}
      <div className={``}>
        <select
          className="mt-1 p-2 w-full border rounded-md"
          value={value}
          name={name}
          onChange={handleChange}
        >
          <option disabled selected value>
            {" "}
            -- select an option --{" "}
          </option>
          {list.map((e) => {
            return (
              <option key={optionValue(e)} value={optionValue(e)}>
                {displayTextFun(e)}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default AddTraining;
