import React from "react";
import { useNavigate } from "react-router-dom";
import HeadingRow from "../components/heading-row/HeadingRow";
import { NavigationWrapper } from "../components/sidebar/Navigation";
const Management = () => {
  const navigate = useNavigate();
  const menuOptions = [
    { title: "Add Curriculum", route: "/add-curriculum" },
    { title: "Add Training", route: "/add-training" },
    { title: "Assign Curriculum", route: "/assign-curriculum" },
  ];

  return (
    <NavigationWrapper
      Child={
        <div>
          <HeadingRow heading="Management" />
          <div className="flex flex-1 gap-4">
            {menuOptions.map((option, index) => (
              <Card title={option.title} route={option.route} />
            ))}
          </div>
        </div>
      }
    />
  );

  function Card({ title, route }) {
    return (
      <div class="relative flex flex-col mt-6 text-gray-700 bg-gray-200  bg-clip-border rounded-xl w-96">
        {/* <div class="p-6">
          <p class="block font-sans text-left antialiased font-light leading-relaxed text-inherit"></p>
        </div>
         */}
        <button
          class="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-rc_orange text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
          type="button"
          onClick={() => {
            navigate(route);
          }}
        >
          {title}
        </button>
      </div>
    );
  }
};

export default Management;
