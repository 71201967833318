import React from "react";
export class State {
  static initial = "initial";
  static loading = "loading";
  static success = "success";
  static failed = "failed";
}
export function Loading() {
  return <div>Loading</div>;
}

export function Failed() {
  return <div>Failed</div>;
}

export function StateBuilder({
  state,
  successUi,
  initialUi = <div>initial</div>,
  LoadingUi = <Loading />,
  FailedUi = <Failed />,
}) {
  switch (state) {
    case State.initial:
      return initialUi;
    case State.loading:
      return LoadingUi;
    case State.success:
      return successUi;
    case State.failed:
      return FailedUi;
    default:
      return initialUi;
  }
}
