import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { MdDone } from "react-icons/md";
import Moment from "react-moment";
import "react-tabs/style/react-tabs.css";
import PageWrapper from "../../components/PageWrapper";
import PercentIndicator from "../../components/PercentIndicator.js";
import { State } from "../../components/StateBuilder";
import "../../components/calendar/Calendar.js";
import CalendarBox from "../../components/calendar/Calendar.js";
import "../../components/calendar/calendar.css";
import "../../components/tabbar/tabbar.css";
import CurriculumService from "../../services/CurriculumService.js";
import TrainingService from "../../services/TrainingService";

const EmpHomePage = () => {
  const [state, setState] = useState(State.initial);
  const [pendingTraining, setTrainings] = useState([]);
  const [completedTrainings, setCompletedTrainings] = useState([]);
  const [curriculum, setCurriculum] = useState([]);
  const [date, onDateChange] = useState(new Date());
  useEffect(() => {
    getTraining();
  }, []);
  const [complianceBriefState, setComplianceBriefState] = useState(
    State.initial
  );
  const [
    {
      avg_attempts,
      avg_score,
      user,
      total_trainings,
      completed_trainings,
      on_time_completion,
    },
    setComplianceBrief,
  ] = useState({});
  useEffect(() => {
    getTrainingComplianceBrief();
  }, []);
  useEffect(() => {
    getCurriculums();
  }, []);
  function getDateBasedBulletColor(date) {
    const today = new Date();
    const _date = new Date(date);
    if (_date.getMonth() === today.getMonth()) {
      return "bg-red-500";
    } else if (_date.getMonth() === today.getMonth() + 1) {
      return "bg-orange-300";
    } else if (
      _date.getMonth() < today.getMonth() &&
      _date.getFullYear() <= today.getFullYear()
    ) {
      return "bg-red-700";
    }
    return "bg-green-600";
  }
  return (
    <PageWrapper
      PageHeading={`Welcome, ${localStorage.getItem("username")}`}
      state={state}
      scrollable={true}
      side_scrollable={true}
      successUi={
        <div className="flex-1">
          <div className="flex pb-2 h-14 bg-white  mb-8">
            <button className="tab-button active">Training Dashboard</button>
          </div>
          <div className="flex">
            <div className="flex-1 text-left bg-gray-200 p-8 rounded-xl">
              <a href="/trainings">
                <h1 className="text-xl font-bold mb-4 text-rc_orange">
                  UPCOMING TASKS
                </h1>
                <div className="marker:text-green list-outside list-disc">
                  {pendingTraining.length == 0 ? "No Training" : ""}
                  {pendingTraining.map((t) => (
                    <div className="flex items-center pt-1">
                      <div
                        className={`w-3 h-3 mr-4 rounded-full ${getDateBasedBulletColor(
                          t.due_date
                        )} text-3xl`}
                      ></div>
                      <div className="flex text-black text-base items-center">
                        <Moment format="DD-MMM-YYYY" className="font-semibold ">
                          {t.due_date}
                        </Moment>
                        {/*   <span className="text-2xl font-thin px-2">|</span> */}
                        <div className=""> &nbsp; - {t.title}</div>
                      </div>
                    </div>
                  ))}
                </div>
                <h1 className="text-xl font-bold mb-4 mt-8 text-rc_orange">
                  COMPLETED
                </h1>
                <div className="marker:text-green list-outside list-disc">
                  {completedTrainings.length == 0 ? "No Training" : ""}
                  {completedTrainings.map((t) => (
                    <div className="flex items-center pt-1">
                      <div
                        className={`w-3 h-3 mr-4 rounded-full ${getDateBasedBulletColor(
                          t.due_date
                        )} text-3xl`}
                      ></div>
                      <div className="flex text-black text-base items-center">
                        <strike>{t.title}</strike>
                      </div>
                    </div>
                  ))}
                </div>
              </a>
            </div>
          </div>
          <a href="/certifications">
            <div className="bg-gray-200 rounded-xl p-8 my-8 text-left">
              <h1 className="text-xl font-bold mb-4 text-rc_orange">
                Your Recent Certifications
              </h1>
              <div>
                {curriculum.length === 0 ? (
                  <div className="text-left">No Recent Certifications</div>
                ) : (
                  <></>
                )}
                {curriculum.map((e) => (
                  <div className="flex items-center text-xl">
                    <MdDone className="bg-black rounded-full text-white p-0.5 mr-8" />
                    <span className=""> {e.name}</span>
                    <span className="mx-2">|</span>
                    <span className=" font-semibold "> {80}%</span>
                  </div>
                ))}
              </div>
            </div>
          </a>
        </div>
      }
      sidebar={
        <>
          <div className="h-14 mb-8"></div>
          <div className="mb-8">
            <CalendarBox value={date} />
          </div>

          <a href="/analytics">
            <div className="text-xl gray-card flex mb-8 items-center">
              <div style={{ width: 75, height: 75 }}>
                <PercentIndicator value={on_time_completion} />
              </div>
              <div className="flex-1 text-left pl-4">
                <p>On time completion</p>
              </div>
            </div>
          </a>

          <a href="/analytics">
            <div className="text-xl gray-card flex mb-8 items-center">
              <div style={{ width: 75, height: 75 }}>
                <PercentIndicator value={avg_score} />
              </div>
              <div className="flex-1 text-left pl-4">
                <p>Average score in all training</p>
              </div>
            </div>
          </a>
        </>
      }
    />
  );

  async function getTraining() {
    setState(State.loading);
    try {
      const pendingTraining = await TrainingService.getTrainingsCompliances({
        trainingStatus: "pending",
      });
      setTrainings(pendingTraining);
      const completedTraining = await TrainingService.getTrainingsCompliances({
        trainingStatus: "completed",
      });

      setCompletedTrainings(completedTraining);
      setState(State.success);
    } catch (error) {
      setState(State.failed);
    }
  }

  async function getTrainingComplianceBrief() {
    setComplianceBriefState(State.loading);
    try {
      const complianceBrief =
        await TrainingService.getTrainingComplianceBrief();
      setComplianceBrief(complianceBrief);
      setComplianceBriefState(State.success);
    } catch (error) {
      setComplianceBriefState(State.failed);
    }
  }

  async function getCurriculums() {
    setState(State.loading);
    try {
      const curriculum = await CurriculumService.getCurriculumAssignment({
        status: "completed",
      });
      setCurriculum(curriculum);
      setState(State.success);
    } catch (error) {
      setState(State.failed);
    }
  }
};

export default EmpHomePage;
