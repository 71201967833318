import React from "react";

const TextField = ({
  label,
  type,
  name,
  value,
  onChange,
  placeholder,
  disabled,
  className,
}) => {
  return (
    <div className={className}>
      {label && (
        <label htmlFor={label} className="block text-gray-700 font-medium">
          {label}
        </label>
      )}
      <div className={``}>
        <input
          name={name}
          type={type}
          id={label}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          className="p-2 w-full border rounded-xl"
        />
      </div>
    </div>
  );
};

export default TextField;
